import '~/javascript/jquery'
import {} from 'jquery-ujs'
import 'bootstrap'
import 'popper.js'
import 'hammerjs'
import 'jquery-match-height'
import select2 from 'select2'
select2()
import init_map from '~/javascript/map'

//= require jquery3
//= require jquery_ujs
//= require popper
//= require bootstrap
//= require hammer
//= require jquery.matchHeight
//= require select2
//= require map

// BS4 carousel patch to support touch using hammer.js
// https://github.com/twbs/bootstrap/issues/17118

function init_carousel_touch_support(){
  $('.carousel').each(function () {
    var $carousel = $(this);
    var hammertime = new Hammer(this, {
      recognizers: [   [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]  ]
    });
    hammertime.on('swipeleft', function () { $carousel.carousel('next'); });
    hammertime.on('swiperight', function () { $carousel.carousel('prev'); });
  });
}

function init_tooltips(){
  $('[data-toggle="tooltip"]').tooltip();
}

function decorate_link_targets() {
  $("a[href^='http']:not([href^='http://www.agim-ece.com']):not([href^='http://agim-ece.com'])").attr("target","_blank");
  $("a[href^='mailto']").attr("target","_blank");
}

function init_file_inputs(){
  $('.custom-file-input').on('change',function(){
    $(this).next('.custom-file-label').addClass("selected").html($(this).val().split('\\').pop());
  })
}

function scroll_to_el(selector){
  $('#all-bodies').one('shown.bs.collapse', function () {
    $('html, body').animate({ scrollTop: ($(selector).offset().top) }, 1000);
  });
}

function init_fancy_selects(){
  $('.fancy-select').each(function(){
    var ph = $(this).data('placeholder');
    var $select = $(this);
    $select.select2({
      theme: 'bootstrap4',
      minimumResultsForSearch: 20,
      allowClear: true,
      placeholder: ph
    });
    $select.on('change', function(){
      $('.fancy-select').not($select).each(function(index, el){  $(el).val(null); });
      $select.closest('form').trigger('submit');
    });
  });
  // https://github.com/select2/select2/issues/3320#issuecomment-350249668
  $('select').on('select2:unselecting', function(ev) {
    if (ev.params.args.originalEvent) {
        // When unselecting (in multiple mode)
        ev.params.args.originalEvent.stopPropagation();
    } else {
        // When clearing (in single mode)
        $(this).one('select2:opening', function(ev) { ev.preventDefault(); });
    }
  });
}

function init_hamburger(){
  $('#hamburger').on('click', function(){
    $(".main-menu").toggleClass('opened');
    $('body').toggleClass('no-scroll');
  });
  $('.main-menu').on('touchmove', function(e){ e.preventDefault(); })
}

// plausible.io + Turbo
function trackVisit() {
  try { plausible('pageview') }
  catch (err) { console.log('Plausible: not available.') }
}

$(document).ready(function(){
  trackVisit();
  init_hamburger();
  init_carousel_touch_support();
  init_tooltips();
  decorate_link_targets();
  init_file_inputs();
  init_fancy_selects();
  init_map();
});

// CSS Styles
import '~/stylesheets/application.scss'
